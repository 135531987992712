import React from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import DetailPageTitle from '../../components/detailPageTitle'
import SectionImprint from "../../components/sections/imprint"

export default function ImprintPage() {
  return (
    <div>
      <Header></Header> 
      <DetailPageTitle title="Impressum"></DetailPageTitle>
      <SectionImprint></SectionImprint>
      <Footer></Footer>
    </div>
  ); 
}
