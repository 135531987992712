import React from "react";
import Header from "../../../components/header";
import Footer from "../../../components/footer";
import DetailPageTitle from "../../../components/detailPageTitle"
import DetailPageSection from "../../../components/sections/systemischeberatung";

import Image from "../../../assets/icons/IconSystemischeBeratung.svg";

export default function SystemischeBeratung() {
  return (
    <div>
      <Header />
      <DetailPageTitle title="Systemische Beratung" image={Image}></DetailPageTitle>
      <DetailPageSection />
      <Footer />
    </div>
  );
}
