import React from "react";
import styles from "./style.module.scss";

import Section from "../../section"
import BackButton from "../../buttonBack"

export default function Coaching() {

  function navigateHome(){
    window.location.href = "/";
  }
  return (
    <Section>
      <div className={"col-md-6 offset-md-3 " + styles.sectionText}>
        <h2>
          Sie suchen als Einzelperson nach einem geeigneten Rahmen, um Anliegen
          aus Ihrem Arbeitsumfeld zu reflektieren? Sie möchte als Team gemeinsam
          konkrete Problemstellungen lösen oder Ihre Zusammenarbeit optimieren?
        </h2>
        <p>
          Organisationen sind in den letzten Jahren schrittweise in Bewegung
          geraten. Wir erleben, wie sich dadurch feste Vorstellungen von Rollen,
          Strukturen und Prozessen – die bisher als Orientierung dienten –
          zunehmend auflösen.
        </p>
        <p className={styles.citation}>
          „Systemische Supervision in Organisationen dient zur Reflektion der
          beruflichen Tätigkeit und Arbeitsbeziehungen - als Einzel-, Team- oder
          konkrete Fallsupervision.“
        </p>
        <p>
          Auf immer mehr Ebenen nehmen MitarbeiterInnen und Teams (zusätzlich zu
          ihren fachlichen Aufgaben) die Aushandlung und Steuerung dieser
          handlungsleitenden „Leitplanken“ eigenständig wahr. Permanenter Wandel
          und der Umgang damit (i.S.v. <i>wir könnten es so oder so machen</i>)
          werden somit zum festen Teil unseres beruflichen Alltags - nicht nur
          in Führung.
        </p>
        <p>
          Supervision kann ein hilfreiches Format sein, um die
          Herausforderungen, die sich daraus ergeben, professionell zu
          reflektieren und zu gestalten - um am Ende (gemeinsam) handlungsfähig
          zu bleiben.
        </p>
        <p>Als Supervisorin</p>
        <ul>
          <li>
            unterstütze ich Sie in der Reflexion Ihrer Anliegen im Umfeld Ihrer
            Arbeitsbeziehungen und der jeweiligen Organisation,
          </li>
          <li>
            biete ich einen strukturierten, geschützten Rahmen mit professionellen
            Methoden, in dem Sie wirksame Lösungen für Ihr Anliegen
            erarbeiten können.
          </li>
        </ul>
        <p>
          Supervision kann als Einzel-, Team- oder konkrete Fallsupervision
          angefragt werden.
        </p>
        <p>
          In der Einzel- und Fallsupervision habe ich – neben der persönlichen
          Arbeit in Präsenz - gute Erfahrungen mit virtuellen Formaten gemacht.
        </p>
        <p>
          In einem unverbindlichen Erstgespräch können wir uns kennenlernen und
          herausfinden, ob mein Angebot für Sie hilfreich sein kann.
        </p>
        <BackButton text="zur&uuml;ck" onClick={navigateHome} />
      </div>
    </Section>
  );
}
