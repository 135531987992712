import React from "react";
import styles from "./style.module.scss";

export default function Section(props) {
  const styleObj = {
    backgroundColor: props.backgroundColor,
    minHeight: "400px"
  };

  return (
    <section id={props.id} className={styles.section + " section container"}>
      <div className={"row " + props.rowClasses} style={styleObj}>
        {props.children}
      </div>
    </section>
  );
}
