import React from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import DetailPageTitle from '../../components/detailPageTitle'
import SectionImprint from "../../components/sections/datenschutz"

export default function PrivacyPage() {
  return (
    <div>
      <Header></Header>
      <DetailPageTitle title="Datenschutz"></DetailPageTitle>
      <SectionImprint></SectionImprint> 
      <Footer></Footer>
    </div>
  ); 
}
