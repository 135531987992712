import React, { useEffect } from "react";
import { BrowserRouter, Route, useLocation, withRouter } from "react-router-dom";

import "./App.css";
import Home from "./pages/homepage";
import Coaching from "./pages/detailpage/coaching";
import Impulse from "./pages/detailpage/impulse";
import Supervision from "./pages/detailpage/supervision";
import SystemischeBeratung from "./pages/detailpage/systemischeBeratung";
import Imprint from "./pages/imprint";
import Datenschutz from "./pages/datenschutz";

function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return props.children;
}
const ScrollToTop = withRouter(_ScrollToTop);

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop>
          <Route exact path="/" component={Home} />
          <Route path="/systemische-beratung" component={SystemischeBeratung} />
          <Route path="/coaching" component={Coaching} />
          <Route path="/impulse-und-austausch" component={Impulse} />
          <Route path="/supervision" component={Supervision} />
          <Route path="/imprint" component={Imprint} />
          <Route path="/datenschutz" component={Datenschutz} />
        </ScrollToTop>
      </BrowserRouter>
    </div>
  );
}

export default App;
