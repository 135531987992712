import React from "react";
import styles from "./style.module.scss";

import Section from "../../section";
import BackButton from "../../buttonBack";

export default function SystemischeBeratung() {
  function navigateHome() {
    window.location.href = "/";
  }
  return (
    <Section>
      <div className={"col-md-6 offset-md-3 " + styles.sectionText}>
        <h2>
          Sie möchten sich mit dem Thema „Neue Arbeit“ für Ihre Organisation
          auseinandersetzen und fragen sich, wie Sie damit starten? Sie suchen
          als Bereich beratende Begleitung in der Umsetzung eines
          Veränderungsprozesses?
        </h2>
        <p>
          In meiner Arbeit richte ich den Fokus auf das relevante „System“ mit
          seinen Beziehungsstrukturen und im jeweiligen Kontext.
          Beratungsanliegen können sein: Unterstützung bei der Umsetzung von
          Veränderungsvorhaben z.B. zur Mobilisierung der Organisation, zur
          strategischen (Neu-)Ausrichtung, aufgrund einer Krisensituation
          oder aus einem Veränderungsdruck heraus.
        </p>
        <p className={styles.citation}>
          „Als Systemische Beraterin begleite ich Organisationen in
          Veränderungs- und Entwicklungsprozessen, die einzelne Bereiche oder
          die gesamte Organisation betreffen.“
        </p>
        <p>
          Zentrale Themen in der systemischen Organisationsberatung sind für
          mich der aktuelle Umbruch und die zukünftige Entwicklung unserer
          Arbeitswelt (auch bekannt unter Begriffen wie New Work / Neue Arbeit
          oder Agilität), mit denen ich mich seit mehr als 8 Jahren
          leidenschaftlich aus theoretischen und ganz praktischen Blickwinkeln
          besch&auml;ftige. Mein Interesse liegt vor allem darin, wie klassisch
          organisierte Unternehmen sich mit dem Thema Neue Arbeit sinnvoll
          auseinandersetzen können:
        </p>
        <ul>
          <li>Welche Aspekte daraus sind für Sie relevant und hilfreich?</li>
          <li>
            Und wie können Sie eigene Wege entwickeln, um z.B. Themen wie
            Selbstorganisation, Führung und Zusammenarbeit - ausgerichtet an Ihren zukünftigen
            Anforderungen - wirksamer zu gestalten?
          </li>
        </ul>
        <p>
          Die Antworten sind je nach Organisation sehr individuell. Der Fokus
          meiner Arbeit liegt insbesondere auf der Ebene von hilfreichen
          Haltungen, Prinzipien und Beziehungsgestaltung hinter Neuer Arbeit.
          In der Beratung lasse ich auch Erfahrungen aus anderen Kontexten und 
          Organisationen einfließen.
        </p>
        <p>Als Beraterin kann ich je nach Bedarf:</p>
        <ul>
          <li>den Gesamtprozess strukturieren,</li>
          <li>den Prozess beratend begleiten und umsetzen</li>
          <li>
            oder auch punktuell für Teilabschnitte beauftragt werden (z.B.
            Workshopmoderationen oder Impulsgebende Formate).
          </li>
        </ul>
        <p>
          Meine Leistungen und das genaue Vorgehen hängen vom jeweiligen Thema,
          Kontext und der Zielsetzung ab. Ihre Konkretisierung ist Teil einer
          intensiven Auftragsklärung zu Beginn eines jeden Prozesses.
        </p>
        <p>Ich berate Sie im persönlichen und auch im virtuellen Raum.</p>
        <p>
          Nach einem unverbindlichen Erstgespräche loten wir gemeinsam aus, ob
          mein Angebot zu ihrem Anliegen passt.
        </p>
        <BackButton text="zur&uuml;ck" onClick={navigateHome} />
      </div>
    </Section>
  );
}
