import React from "react";
import styles from "./style.module.scss";

import Section from "../../section";
import ContactForm from "../../contactForm";
// import Button from "../../button";

export default function SectionContact() {
  return (
    <Section
      id="sectionContact"
      backgroundColor="#F4F4F4"
      rowClasses="justify-content-md-center"
    >
      <div className={styles.sectionContent}>
        <div className={"col-md-12 text-center " + styles.contactInfo}>
          <h1>Kontakt</h1>
          <p>Anja Distefano</p>
          <p>hello@anja-distefano.de</p>
          <p>
            <a href="tel:+4983662600825">+49 (0) 8366 - 2600 825</a>
          </p>
        </div>

        <ContactForm />
      </div>
    </Section>
  );
}
