import React from "react";
import styles from "./style.module.scss";
import Section from "../../section";
import imgHowIWork from "../../../assets/images/how_i_work.jpg";
import imgHowIWorkP from "../../../assets/images/how_i_work.webp";

import Button from "../../button"

export default function SectionHowIWork() {

  function scrollToOffer() {
    var contactSection = document.getElementById("sectionOffer");
    var scrollOptions = {
      behavior: "smooth",
    };
    contactSection.scrollIntoView(scrollOptions);
  }

  return (
    <Section id="sectionHowIWork" backgroundColor="#CDCBCC">
      <div className={"col-sm-5 col-md-5 " + styles.sectionImage}>
        <picture>
          <source media="(min-width:0)" srcSet={imgHowIWorkP} type="image/webp" />
          <source media="(min-width:0)" srcSet={imgHowIWork} type="image/jpeg" />
          <img
            src={imgHowIWork}
            width="100%"
            alt="Anja Distefano - Wie ich arbeite"
          />
        </picture>
      </div>
      <div className={"col-sm-7 col-md-7 " + styles.sectionText}>
        <h1>Wie ich arbeite</h1>
        <h2>
          In meiner Arbeit begleite ich Menschen und Organisationen dabei,
          Themen wie Pers&ouml;nlichkeitsentwicklung, Teamprozesse,
          Kulturentwicklung oder Transformation (neu) zu erfahren, besprechbar
          und dadurch handhabbarer zu machen.
        </h2>
        <p>
          Ver&auml;nderung und Entwicklung braucht Mut, Zuversicht und einen
          gesch&uuml;tzten Raum, in dem wir uns eingeladen f&uuml;hlen, den
          Blick zu weiten, gewohnte Denk- und Handlungsweisen zu verlassen und
          neue Muster auszuprobieren.
        </p>
        <p>
          Mit professioneller Erfahrung und feinem Gesp&uuml;r unterst&uuml;tze
          ich meine KundInnen dabei, sich als Mensch oder Team (wieder) mit sich
          und anderen zu verbinden und dadurch „in die eigene Kraft“ zu kommen.
          Gleichzeitig stelle ich meinen Blick als „Beobachterin“ zur Verfügung
          und rege an, neue Perspektiven einzunehmen, um daraus hilfreiche
          Erkenntnisse für wirksame L&ouml;sungen zu gewinnen. Dabei lasse ich –
          wo hilfreich und gew&uuml;nscht - Erfahrungen aus anderen Kontexten
          und Organisationen einflie&szlig;en.
        </p>
        <p>
          In meiner Arbeit betrachte ich mein Gegen&uuml;ber mit systemischem
          Blick - ganzheitlich und immer bezogen auf die jeweilige Kultur und
          Lebenswelt.
        </p>

        <Button text="Zu meinem Angebot" onClick={scrollToOffer}></Button>
      </div>
    </Section>
  );
}
