import React from "react";
import styles from "./style.module.scss";
import Section from "../../section";

import imgAnjaProfil from "../../../assets/images/Anja_About_500x533.jpg";
import imgAnjaProfilWebP from "../../../assets/images/Anja_About_500x533.webp";
import ButtonDownload from "../../buttonDownload";

import profile from "../../../assets/Beraterinnenprofil_Anja_Distefano.pdf"

export default function SectionAboutMe() {
  return (
    <Section id="sectionAboutMe" backgroundColor="#D7E0E5">
      <div className={"col-sm-7 col-md-7 " + styles.sectionText}>
        <h1>&Uuml;ber mich</h1>
        <h2>Neugierig, wertsch&auml;tzend, positiv, verbunden.</h2>
        <p>
          Geboren Anfang der 80er geh&ouml;re ich zum &auml;lteren Teil der
          sogenannten Generation Y. Beruflich „gro&szlig; geworden“ bin ich in
          klassischen, hierarchisch gepr&auml;gten Unternehmen. Diese
          Erfahrungen verbinde ich mit einem neugierigen Blick in Richtung neuer
          Formen der Zusammenarbeit und F&uuml;hrung.
        </p>
        <p>
          In meiner Arbeit &auml;u&szlig;ern sich diese Einfl&uuml;sse in einem
          wertsch&auml;tzenden Blick f&uuml;r das, was sich bei meinem
          Gegen&uuml;ber aus der Vergangenheit entwickelt hat. Gleichzeitig lade
          ich ein - mit Offenheit und Kreativit&auml;t – gemeinsam zu
          entwickeln, was es f&uuml;r die Zukunft braucht, um wirksam und erfolgreich 
          zu sein.
        </p>
        <p>
          Als systemische Beraterin, Coach und Supervisorin unterst&uuml;tze ich
          Menschen, Teams und Organisationen in ihren aktuellen Entwicklungs-
          und Ver&auml;nderungsprozessen.
        </p>
        <ButtonDownload text="Profil herunterladen" href={profile} target="blank" />
      </div>
      <div className={"col-sm-5 col-md-5 " + styles.sectionImage}>
        <picture>
          <source media="(min-width:0)" srcSet={imgAnjaProfilWebP} type="image/webp" />
          <source media="(min-width:0)" srcSet={imgAnjaProfil} type="image/jpeg" />
          <img src={imgAnjaProfil} width="100%" alt="Anja Profil" />
        </picture>
      </div>
    </Section>
  );
}
