import React from "react";
import { NavLink } from "react-router-dom";

import styles from "./style.module.scss";

import logoDGTA from "../../assets/images/dgta_logo_1000.jpg";
import logoSG from "../../assets/images/systemische_gesellschaft_logo.png";
import logoXing from "../../assets/icons/xing.svg";
class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { currentYear: new Date().getFullYear() };
  }
  render() {
    return (
      <section>
        <div id="footer">
          <div className="container">
            <div
              className={
                "row align-items-center " + styles.footerRowMemberships
              }
            >
              <div className="col-8">
                <p>Mitglied bei</p>
                <a href="https://systemische-gesellschaft.de/" target="blank">
                  <picture>
                    <img src={logoSG} alt="Systemmische Gesellschaft" />
                  </picture>
                </a>
              </div>
              <div className="col-4 text-end">
                <a
                  href="https://www.xing.com/profile/Anja_Distefano/cv"
                  target="blank"
                >
                  <img
                    className="float-end"
                    src={logoXing}
                    alt="Xing Logo"
                  ></img>
                </a>
              </div>
            </div>

            <div className={"row align-items-center " + styles.footerRow}>
              <div className="col-sm-6">
                <NavLink to="/datenschutz">Datenschutz</NavLink>
                &nbsp;|&nbsp;
                <NavLink to="/imprint">Impressum</NavLink>
              </div>
              <div className="col-sm-6 text-end">
                &#169; {this.state.currentYear} Anja Distefano
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Footer;
