import React from "react";
import styles from "./style.module.scss";

export default function DetailPageTitle(props) {

  var img;
  if (props.image) {
    img = <img src={props.image} alt="Title Logo" />;
  }

  return (
    <div className="container">
      <div className={"row align-items-center " + styles.titleRow}>
        <div className="col-md-6">
          <h1>{props.title}</h1>
        </div>
        <div className={"col-md-6 " + styles.titleImage}>{img}</div>
      </div>
    </div>
  );
}

