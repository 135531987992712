import React from "react";
import styles from "./style.module.scss";

import Section from "../../section";
import BackButton from "../../buttonBack";

export default function Imprint() {

  function navigateHome() {
    window.location.href = "/";
  }
  return (
    <Section>
      <div className={"col-md-6 offset-md-3 " + styles.sectionText}>
        <h2>
          Sie suchen als Team, Organisation oder Netzwerk frische Impulse zu
          aktuellen Veränderungsthemen? Sie haben Lust, sich in neuen Formaten
          miteinander auszutauschen? Oder benötigen Sie Unterstützung bei der
          Moderation ihrer Veranstaltung?
        </h2>
        <p>
          Ich biete impulsgebende Vorträge und dialogische Austauschformate, in
          denen die Teilnehmenden Themen rund um Veränderung, Entwicklung,
          Kultur und Neue Arbeit explorieren und für ihre Arbeit bzw. ihre
          Organisation weiterdenken können. Auf Wunsch werden die Inhalte durch
          moderierte Workshops intensiviert.
        </p>
        <p className={styles.citation}>
          „Impulsvorträge & dialogische Austauschrunden können Menschen in
          Organisationen einen anregenden Einstieg in Themen rund um
          Veränderung, Kulturentwicklung und Neue Arbeit ermöglichen.“
        </p>
        <p>
          Mögliche Themen für Impulsvorträge &amp; Austauschformate können sein:
        </p>
        <ul>
          <li>
            Gestaltung sinnstiftender Formen von Zusammenarbeit und Führung,
          </li>
          <li>Wirksamer Umgang mit Komplexität &amp; Dynamik,</li>
          <li>
            Gestaltung von sozialen &amp; physischen Räumen im Sinne Neuer
            Arbeit,
          </li>
          <li>Selbstorganisation &amp; Führung,</li>
          <li>Virtuelle Führung und Zusammenarbeit von Teams</li>
          <li>und weitere...</li>
        </ul>
        <p>
          Neben der Durchführung unterstütze ich Sie auch in der Konzeption &
          Vorbereitung Ihrer Veranstaltungen, um die Inhalte und das Vorgehen
          möglichst stimmig an Ihren Unternehmenskontext anzudocken. Alle
          Formate können unternehmensintern, z.B. für ein (Projekt-)Team / einen
          Bereich oder auch unternehmensübergreifend für offene Veranstaltungen
          angefragt werden.
        </p>
        <p>
          Die Impulsvorträge und Moderationen können in Präsenz oder virtuell
          stattfinden. Dabei nutze ich auch gerne Ihr gewohntes
          Video-Konferenz-Tool.
        </p>
        <BackButton text="zur&uuml;ck" onClick={navigateHome} />
      </div>
    </Section>
  );
}
