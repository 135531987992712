import React from "react";

import Header from "../../components/header";
import Footer from "../../components/footer";
import Section1Hero from "../../components/sections/1_hero";
import Section2Offer from "../../components/sections/2_offer";
import Section3HowIWork from "../../components/sections/3_howIWork";
import Section4AboutMe from "../../components/sections/4_aboutMe";
import Section5Contact from "../../components/sections/5_contact";
import SectionVirtuelleZusammenarbeit from "../../components/sections/virtuelleZusammenarbeit";

export default function Home() {
  return (
    <div>
      <Header></Header>
      <Section1Hero></Section1Hero>
      <Section2Offer></Section2Offer>
      <Section3HowIWork></Section3HowIWork>
      <SectionVirtuelleZusammenarbeit></SectionVirtuelleZusammenarbeit>
      <Section4AboutMe></Section4AboutMe>
      <Section5Contact></Section5Contact>
      <Footer></Footer>
    </div>
  );
}
