import useVisible from "../../customHooks/useVisible";

import { NavLink } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";


import styles from "./style.module.scss";
import logoIcon from "../../assets/icons/LogoBildzeichen.svg";

export default function Header() {
  const { ref, isVisible, setIsVisible } = useVisible(false);

  return (
    <div>
      <div id="header" className="container" ref={ref}>
        <nav className="navbar navbar-light fixed-top">
          <div className="container-md">
            <a className="navbar-brand" href="/">
              <div className="d-flex align-items-center">
                <img
                  src={logoIcon}
                  className={styles.logoIcon}
                  alt="logoIcon"
                />
                <div className={styles.logoText} >
                Anja Distefano
                </div>
              </div>
            </a>
            <span
              className={
                styles.navbarTogglerIcon +
                " " +
                (isVisible ? styles.navbarOpen : styles.navbarClosed)
              }
              onClick={(e) => {
                setIsVisible(!isVisible);
              }}
            ></span>
          </div>
        </nav>
      </div>

      <div className={styles.sidebarContainer}>
        <div
          id="navbarMenu"
          className={
            styles.sidebarMenu +
            " container " +
            (isVisible ? styles.sidebarMenuVisible : "")
          }
        >
          <div className={styles.navbarMenuItemContainer}>
            <ul className={"flex-column " + styles.navbarMenuItems}>
              <li className={styles.navItem}>
                <NavHashLink to="/#top" activeClassName={styles.selected}>
                  Home
                </NavHashLink>
              </li>

              <li className={styles.navItem}>
                <NavHashLink
                  to="/#sectionOffer"
                  activeClassName={styles.selected}
                >
                  Mein Angebot
                </NavHashLink>

                <ul className={"flex-column " + styles.navbarMenuItemsSub}>
                  <li className={styles.navItemSub}>
                    <NavLink
                      to="/systemische-beratung"
                      activeClassName={styles.selected}
                    >
                      Systemische Beratung
                    </NavLink>
                  </li>
                  <li className={styles.navItemSub}>
                    <NavLink to="/coaching" activeClassName={styles.selected}>
                      Coaching
                    </NavLink>
                  </li>
                  <li className={styles.navItemSub}>
                    <NavLink
                      to="/supervision"
                      activeClassName={styles.selected}
                    >
                      Supervision
                    </NavLink>
                  </li>
                  <li className={styles.navItemSub}>
                    <NavLink
                      to="/impulse-und-austausch"
                      activeClassName={styles.selected}
                    >
                      Impulse &amp; Austausch
                    </NavLink>
                  </li>
                </ul>
              </li>

              <li className={styles.navItem}>
                <NavHashLink
                  to="/#sectionHowIWork"
                  activeClassName={styles.selected}
                >
                  Vorgehen
                </NavHashLink>
              </li>

              <li className={styles.navItem}>
                <NavHashLink
                  to="/#sectionAboutMe"
                  activeClassName={styles.selected}
                >
                  &Uuml;ber mich
                </NavHashLink>
              </li>

              <li className={styles.navItem}>
                <NavHashLink
                  to="/#sectionContact"
                  activeClassName={styles.selected}
                >
                  Kontakt
                </NavHashLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
