import React from "react";
import styles from "./style.module.scss";

import ArrowIcon from "../../assets/icons/Arrow.svg"

export default function Button(props) {
  return (
    <button
      type="button"
      className={"btn " + styles.button}
      onClick={props.onClick}
      disabled={props.disabled}
      type={props.type !== null && props.type !== "" ? props.type : "button"}
    >
      <img src={ArrowIcon} alt="buttonBack" ></img>
      {props.text}
    </button>
  );
}
