import React from "react";
import { NavHashLink } from "react-router-hash-link";

import styles from "./style.module.scss";
import Section from "../../section";

import iconVirtuellezusammenarbeit from "../../../assets/icons/IconVirtuelleZusammenarbeit.svg";
export default function SectionHowIWork() {
  return (
    <Section
      id="sectionHowIWork"
      backgroundColor="#f0f7f7"
      rowClasses="align-items-center"
    >
      <div className={"col-sm-3 col-md-3 " + styles.sectionImage}>
        <img
          src={iconVirtuellezusammenarbeit}
          alt="Icon virtuelle Zusammenarbeit"
        />
      </div>
      <div className={"col-sm-9 col-md-9 " + styles.sectionText}>
        <h1>Virtuelle Zusammenarbeit</h1>
        <p>
          In den vergangenen Monaten habe ich sehr positive Erfahrungen mit der
          virtuellen Zusammenarbeit in verschiedensten Formaten gemacht. Gerade
          in Zeiten der Pandemie biete ich{" "}
          <NavHashLink to="/#sectionOffer" activeClassName="selected">
            <span className={styles.highlight}>alle Angebote</span>
          </NavHashLink>
          &nbsp;auf Wunsch auch im virtuellen Raum an. Idealerweise nutze ich dabei
          das gewohnte Video-Konferenz-Tool meiner KundInnen, zu denen es
          (vielleicht) bereits praktische Erfahrungen gibt.
        </p>
        <p>
          Alternativ kann ich jederzeit einen virtuellen Begegnungsraum über das
          Tool Microsoft Teams anbieten.
        </p>
      </div>
    </Section>
  );
}
