import React from "react";
import Header from "../../../components/header";
import Footer from "../../../components/footer";
import DetailPageTitle from "../../../components/detailPageTitle";
import DetailPageSection from "../../../components/sections/supervision";

import Image from "../../../assets/icons/IconSupervisionWhite.svg";

export default function Supervision() {
  return (
    <div>
      <Header />
      <DetailPageTitle title="Supervision" image={Image}></DetailPageTitle>
      <DetailPageSection />
      <Footer />
    </div>
  );
}
