import React from "react";
import Header from "../../../components/header";
import Footer from "../../../components/footer";
// import DetailPageTitle from "../..components/detailPageTitle";
import DetailPageTitle from "../../../components/detailPageTitle"
import DetailPageSection from "../../../components/sections/impulse";

import Image from "../../../assets/icons/IconImpulseAustausch.svg";

export default function Impulse() {
  return (
    <div>
      <Header />
      <DetailPageTitle title="Impulse & Austausch" image={Image}></DetailPageTitle>
      <DetailPageSection />
      <Footer />
    </div>
  );
}
