import React from "react";
import styles from "./style.module.scss";

export default function Button(props) {
  return (
    <a
      href={props.href}
      className={"btn " + styles.button}
      tabIndex="-1"
      role="button"
      target={props.target}
    >
      {props.text}
    </a>
  );
}
