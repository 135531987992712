import React from "react";
import { Link } from "react-router-dom";
import styles from "./style.module.scss";

function OfferTile(props) {
  return (
    <Link to={props.href} className={styles.offerAreaLink}>
      <div className={styles.offerArea}>
        <div className={styles.offerAreaItems}>
          <img src={props.iconSrc} alt="{'Icon ' + props.text}" />
          <p>{props.text}</p>
        </div>
      </div>
    </Link>
  );
}

export default OfferTile;
