import React from "react";
import Button from "../button";

import styles from "./style.module.scss";
import InfoIcon from "../../assets/icons/info.svg";
import SendingIcon from "../../assets/icons/loader.svg";
import SuccessIcon from "../../assets/icons/success.svg";
import ErrorIcon from "../../assets/icons/error.svg";

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      message: "",
      website: "",
      privacyAccepted: false,
      isSending: false,
      isSent: false,
      isError: false,
    };
  }

  updateField(field, value) {
    this.setState({ [field]: value });
  }

  sendContactForm = async (event) => {
    event.preventDefault();

    event.target.className += " was-validated";

    if (event.target.checkValidity()) {
      this.setState({ isSending: true });
      await fetch(
        "https://pb-func-weu-d-003.azurewebsites.net/api/ContactFormHttpTrigger?code=NwOVGbpAWpYKJcWmOhuK3kGv31Tk07o9BlmEFtUZt9164/aKge1vIg==",
        {
          // mode: 'no-cors',
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(this.state),
        }
      ).then((response) => {
        this.setState({
          isSending: false,
          isSent: response.ok,
          isError: !response.ok,
        });
      }).catch((error) => {
        this.setState({
          isSending: false,
          isSent: false,
          isError: true
        });
      });
    }
  };

  render() {
    return (
      <div className={styles.contact}>
        <form
          className={styles.form + " needs-validation"}
          onSubmit={this.sendContactForm}
          noValidate
        >
          <div className="row justify-content-md-center">
            <div className="col-md-4 col-sm-12">
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="inputName"
                  placeholder="Name"
                  onChange={(event) =>
                    this.updateField("name", event.target.value)
                  }
                  value={this.state.name}
                  required
                />
                <label htmlFor="inputName">Name</label>
              </div>
            </div>
            <div className="col-md-4 col-sm-12">
              <div className="form-floating">
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="Email"
                  onChange={(event) =>
                    this.updateField("email", event.target.value)
                  }
                  value={this.state.email}
                  required
                />
                <label htmlFor="email" className="form-label">
                  Email
                </label>
              </div>
            </div>
          </div>
          <div className="row justify-content-md-center">
            <div className="col-md-8 col-sm-12">
              <div className="form-floating">
                <textarea
                  type="text"
                  className="form-control"
                  id="message"
                  rows="6"
                  placeholder="Ihre Nachricht"
                  onChange={(event) =>
                    this.updateField("message", event.target.value)
                  }
                  value={this.state.message}
                  required
                />
                <label htmlFor="message" className="form-label">
                  Ihre Nachricht
                </label>
              </div>
            </div>
          </div>
          {/* Honeypot Field, do not fill, unless you are a bot */}
          <div className="row justify-content-md-center d-none">
            <div className="col-md-8 col-sm-12">
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="website"
                  placeholder="!leave empty!"
                  onChange={(event) =>
                    this.updateField("website", event.target.value)
                  }
                  value={this.state.website}
                />
                <label htmlFor="website" className="form-label">
                  !leave empty!
                </label>
              </div>
            </div>
          </div>
          <div className="row justify-content-md-center">
            <div className="col-md-4 col-sm-4">
              <input
                type="checkbox"
                className="form-check-input"
                id="checkPrivacy"
                onChange={(event) =>
                  this.updateField("privacyAccepted", event.target.checked)
                }
                value={this.state.privacyAccepted}
                required
              />
              <label className="form-check-label" htmlFor="checkPrivacy">
                Datenschutz
                <div className={styles.tooltip}>
                  <img src={InfoIcon} alt="Datenschutz Info Icon"></img>
                  <div className={styles.tooltipText}>
                    Ich stimme zu, dass meine Angaben aus dem Kontaktformular
                    zur Beantwortung meiner Anfrage erhoben und verarbeitet
                    werden. Die Daten werden nach abgeschlossener Bearbeitung
                    Ihrer Anfrage gelöscht. <br />
                    Hinweis: Sie können Ihre Einwilligung jederzeit für die
                    Zukunft per E-Mail an hello@anja-distefano.de widerrufen.
                    Detaillierte Informationen zum Umgang mit Nutzerdaten finden
                    Sie in der Datenschutzerklärung.
                  </div>
                </div>
              </label>
            </div>
            {/* </div>
          <div className={"row justify-content-md-center " + styles.sendButton}> */}
            <div className="col-md-4 col-sm-4 text-end">
              <Button
                text="Absenden"
                type="submit"
                disabled={this.state.isSending || this.state.isSent}
              ></Button>
            </div>
          </div>

          <div className="row justify-content-md-center">
            <div className="col-md-8">
              <div
                className={
                  "alertArea " +
                  (this.state.isSending ||
                  this.state.isSent ||
                  this.state.isError
                    ? "visible"
                    : "invisible")
                }
              >
                <div
                  className={
                    styles.alert +
                    " alert alert-success d-flex align-items-center " +
                    (this.state.isSending ? "" : "d-none")
                  }
                  role="alert"
                >
                  <img src={SendingIcon} alt="Nachricht wird gesendet..."></img>
                  <p>Nachricht wird gesendet...</p>
                </div>
                <div
                  className={
                    styles.alert +
                    " alert alert-success d-flex align-items-center " +
                    (this.state.isSent ? "" : "d-none")
                  }
                  role="alert"
                >
                  <img
                    src={SuccessIcon}
                    alt="Icon Nachricht erfolgreich gesendet"
                  ></img>
                  <p>Danke für die Nachricht!</p>
                </div>
                <div
                  className={
                    styles.alert +
                    " alert alert-danger d-flex align-items-center " +
                    (this.state.isError ? "" : "d-none")
                  }
                  role="alert"
                >
                  <img
                    src={ErrorIcon}
                    alt="Icon Nachricht fehler beim Senden"
                  ></img>
                  <p>
                    Ups, da ist wohl etwas schief gelaufen. Kontaktieren Sie
                    mich gerne direkt per E-Mail: <br />
                    <a href="mailto:hello@anja-distefano.de">hello@anja-distefano.de</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default ContactForm;
