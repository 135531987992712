import React from "react";
import styles from "./style.module.scss";

import Section from "../../section";
import imgAnja from "../../../assets/images/Anja_Hero_800x640.jpg";
import imgAnjaWebP from "../../../assets/images/Anja_Hero_800x640.webp";
import Button from "../../button";

export default function SectionHero() {
  function scrollToContact() {
    var contactSection = document.getElementById("sectionContact");
    var scrollOptions = {
      behavior: "smooth",
    };
    contactSection.scrollIntoView(scrollOptions);
  }

  return (
    <div>
      <div className={styles.teaserXS}>
        <Section id="sectionHero" backgroundColor="#D7E0E5">
          <div className="col-sm-12">
            <div className={styles.teaserText}>
              <h1>
                <span>
                  Ich begleite Menschen in Ver&auml;nderungs
                  <wbr />
                  prozessen.
                </span>
              </h1>

              <Button text="Kontakt" onClick={scrollToContact}></Button>
            </div>
            <div className={styles.teaserImage}>
              <picture>
                <source media="(min-width:0)" srcSet={imgAnjaWebP} type="image/webp" />
                <source media="(min-width:0)" srcSet={imgAnja} type="image/jpeg" />
                <img src={imgAnja} width="100%" alt="Anja Distefano" />
              </picture>
            </div>
          </div>
        </Section>
      </div>

      <div className={styles.teaser}>
        <Section id="sectionHero" backgroundColor="#FFFFFF">
          <div className="col-md-6">
            <div className={styles.teaserLeft}>
              <h1>Ich begleite Menschen in Ver&auml;nderungsprozessen.</h1>
              <Button text="Kontakt" onClick={scrollToContact}></Button>
            </div>
          </div>
          <div className={"col-md-6 " + styles.teaserRight}>
            <picture>
              <source media="(min-width:0)" srcSet={imgAnjaWebP} type="image/webp" />
              <source media="(min-width:0)" srcSet={imgAnja} type="image/jpeg" />
              <img src={imgAnja} width="100%" alt="Anja Distefano" />
            </picture>
          </div>
        </Section>
      </div>
    </div>
  );
}
