import React from "react";
import styles from "./style.module.scss";

import Section from "../../section";
import OfferTile from "../../offerTile";
import iconSystemischeBeratung from "../../../assets/icons/IconSystemischeBeratung.svg";
import iconImpulseAustausch from "../../../assets/icons/IconImpulseAustausch.svg";
import iconSupervision from "../../../assets/icons/IconSupervision.svg";
import iconCoaching from "../../../assets/icons/IconCoaching.svg";

// const { createRef, useState, useEffect } = React;

export default function SectionOffer() {
  // const [isSticky, setIsSticky] = useState(false);
  // const ref = createRef();

  // // mount
  // useEffect(() => {
  //   const cachedRef = ref.current,
  //     observer = new IntersectionObserver(
  //       ([e]) => setIsSticky(e.intersectionRatio < 1),
  //       { threshold: [1] }
  //     );

  //   observer.observe(cachedRef);

  //   // unmount
  //   return function () {
  //     observer.unobserve(cachedRef);
  //   };
  // }, []);

  return (
    // <div className={"sticky-md-top" + (isSticky ? " isSticky" : "")}>
    // <div className={"sticky-md-top"}>
    <div>
      <Section
        id="sectionOffer"
        backgroundColor="#F0F7F7"
        rowClasses="align-items-center justify-content-md-center row-cols-md-4 row-cols-sm-2"
      >
        <div className={"col " + styles.sectionCol}>
          <OfferTile
            iconSrc={iconSystemischeBeratung}
            text="Systemische Beratung"
            href="/systemische-beratung"
          ></OfferTile>
        </div>
        <div className={"col " + styles.sectionCol}>
          <OfferTile
            iconSrc={iconCoaching}
            text="Coaching"
            href="/coaching"
          ></OfferTile>
        </div>
        <div className={"col " + styles.sectionCol}>
          <OfferTile
            iconSrc={iconSupervision}
            text="Supervision"
            href="/supervision"
          ></OfferTile>
        </div>
        <div className={"col " + styles.sectionCol}>
          <OfferTile
            iconSrc={iconImpulseAustausch}
            text="Impulse &amp; Austausch"
            href="/impulse-und-austausch"
          ></OfferTile>
        </div>
      </Section>
    </div>
  );
}
