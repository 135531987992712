import React from "react";
import Header from "../../../components/header";
import Footer from "../../../components/footer";
import DetailPageTitle from "../../../components/detailPageTitle";
import DetailPageSection from "../../../components/sections/coaching";

import Image from "../../../assets/icons/IconCoaching.svg";

export default function Coaching() {
  return (
    <div>
      <Header />
      <DetailPageTitle title="Coaching" image={Image}></DetailPageTitle>
      <DetailPageSection />
      <Footer />
    </div>
  ); 
}
