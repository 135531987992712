import React from "react";
import styles from "./style.module.scss";

import Section from "../../section"
import BackButton from "../../buttonBack"

export default function Coaching() {

  function navigateHome(){
    window.location.href = "/";
  }
  return (
    <div>
      <Section>
        <div className={"col-md-6 offset-md-3 " + styles.sectionText}>
          <h2>
            Sie sind als Einzelperson auf der Suche nach einem Coach, der Sie
            bei einem persönlichen Entwicklungsanliegen unterstützt? Sie suchen
            Wege, um als Führungskraft mit Ihrer Kraft, Aufmerksamkeit und Zeit
            sinnvoll umzugehen? Oder haben Sie eine konkrete Fragestellung, zu
            der Sie sich Beratung und Austausch wünschen?
          </h2>
          <p>
            All das können mögliche Anliegen für einen Coachingprozess sein.
          </p>
          <p className={styles.citation}>
            „Als Systemischer Coach begleite ich Sie in der Bearbeitung
            persönlicher Entwicklungsanliegen im beruflichen Kontext und in der
            Führungsrolle.“
          </p>
          <p>
            Grundsätzlich gehe ich davon aus, dass Menschen alle Kompetenzen,
            die sie für einen wirksamen Umgang mit ihren Fragestellungen und
            Herausforderungen brauchen, bereits in sich tragen. Das Problem ist
            häufig nicht, dass jemand „einfach nur nicht weiß“ was er tun soll,
            sondern dass er in schwierigen Situationen oft nicht den Zugriff zu
            all seinen wertvollen Fähigkeiten, Kompetenzen und Ressourcen hat.
          </p>
          <p>Im Coaching unterstütze ich Sie darin</p>
          <ul>
            <li>
              nach diesen Zugängen zu Ihren Fähigkeiten und Ressourcen zu
              forschen,
            </li>
            <li>
              Ihre Probleme aus unterschiedlichen Perspektiven zu explorieren,
            </li>
            <li>
              hinderliche Muster oder einschränkende Überzeugungen zu erkennen
            </li>
            <li>
              und auch die guten Gründe für Ihr aktuelles Verhalten zu sehen.
            </li>
          </ul>
          <p>
            Im Coachingprozess entwickeln wir konkrete Ideen, die Sie z. B. zu
            hilfreichen Erkenntnissen und/oder mehr Klarheit bezüglich Ihres
            Anliegens führen können. Ein veränderter Blick kann neue
            Möglichkeiten eröffnen, um einen wirksam(er)en Umgang mit einem
            aktuellen Thema zu finden oder (anders) ins Handeln zu kommen. Dafür
            gestalte ich als Coach den professionellen Rahmen und stelle auch
            meinen Blick als neugierige und wertschätzende „Beobachterin“ zur
            Verfügung.
          </p>
          <p>
            Das Coaching kann in Präsenz, im virtuellen Raum und auf Wunsch auch
            unter freiem Himmel stattfinden. Wir loten gemeinsam aus, was ein
            guter Raum für Ihr Anliegen sei kann.
          </p>
          <p>
            In einem unverbindlichen Erstgespräch können wir uns kennenlernen
            und herausfinden, ob mein Angebot für Sie das Richtige ist.
          </p>
          <BackButton text="zur&uuml;ck" onClick={navigateHome} />
        </div>
      </Section>
    </div>
  );
}
